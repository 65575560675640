import { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const LandingContainer = lazy(() => import('../containers/LandingContainer/LandingContainer'))
const ContactContainer = lazy(() => import('../containers/ContactContainer/ContactContainer'))

export const Navigation: React.FC = () => {
    
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={<LandingContainer />} />
            <Route path="/contact" element={<ContactContainer />} />
        </Routes>
    </BrowserRouter>
}

export const TempNavigation: React.FC = () => {

    return <>
    <LandingContainer />
    <ContactContainer />
    </>
}